<template>
    <content-loader :height="550" secondaryColor="#ccd8ff" primaryColor="#e7ecff">
        <rect x="55" y="50" rx="0" ry="0" width="260" height="30" />
        <rect x="95" y="120" rx="20" ry="20" width="180" height="50" />
        <rect x="55" y="200" rx="50" ry="50" width="15" height="15" />
        <rect x="80" y="200" rx="5" ry="5" width="230" height="15" />
        <rect x="55" y="250" rx="50" ry="50" width="15" height="15" />
        <rect x="80" y="250" rx="5" ry="5" width="230" height="15" />
        <rect x="55" y="300" rx="50" ry="50" width="15" height="15" />
        <rect x="80" y="300" rx="5" ry="5" width="230" height="15" />
        <rect x="55" y="350" rx="50" ry="50" width="15" height="15" />
        <rect x="80" y="350" rx="5" ry="5" width="230" height="15" />
        <rect x="55" y="400" rx="50" ry="50" width="15" height="15" />
        <rect x="80" y="400" rx="5" ry="5" width="230" height="15" />
        <rect x="55" y="450" rx="50" ry="50" width="15" height="15" />
        <rect x="80" y="450" rx="5" ry="5" width="230" height="15" />
        <rect x="55" y="500" rx="50" ry="50" width="15" height="15" />
        <rect x="80" y="500" rx="5" ry="5" width="230" height="15" />
    </content-loader>
</template>

<script>
    import { ContentLoader } from 'vue-content-loader';
    export default {
        components : {
            ContentLoader
        }
    }
</script>
